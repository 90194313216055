import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Project = props => {
  return (
    <article className="card card--link card--project">
      <Link className="link" to={props.data.path}>
        <div className="image"><Img fluid={props.image} alt={props.data.images[0].alt } /></div>
        <div className="details">
          <h1 className="title" >{props.data.title}</h1>
          <p className="description">{props.data.description}</p>
          <button className="button">
            Voir <span className="hide-small-mobile">le projet</span>
          </button>
        </div>
      </Link>
    </article>
  )
}

export default Project
