import React from 'react'

const Client = props => {
  const logoPath = require('@images/clients/' + props.data.logo)
  return (
    <div className="client">
      <a href={props.data.website} target="_blank">
        <img className="client__logo" src={logoPath} alt={props.data.name} />
      </a>
    </div>
  )
}

export default Client
