// React components :
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '@components/layout/layout'
import SEO from '@shared/seo'
import Container from '@shared/container'
import Spacer from '@shared/Spacer'
import SectionHeadline from '@components/pages/home/SectionHeadline'
import ProjectCard from '@components/pages/projects/ProjectCard'
import ServiceHomeCard from '@components/pages/services/ServiceHomeCard'
import ClientCard from '@components/pages/home/ClientCard'
import seoMeta from '@seo-data/index'

const IndexPage = () => {
  const data = useStaticQuery(query)

  // Load projects
  const projects = data.projects.edges.map(item => item.node).slice(0, 3)
  const projectsImages = data.projectsImages.edges.map(item => item.node)
  const getImage = project => {
    const imageFluid = projectsImages.find(el => el.relativePath === project.images[0].src).childImageSharp.fluid
    return imageFluid
  }
  const projectsList = projects.map((project, index) => (
    <ProjectCard key={index} data={project} image={getImage(project)} />
  ))

  // Load Services
  const services = data.services.edges.map(item => item.node).slice(0, 3)
  const servicesList = services.map((service, index) => (
    <ServiceHomeCard key={index} data={service} />
  ))

  // Load Clients
  const clients = data.clients.edges.map(item => item.node)
  const clientsList = clients.map((service, index) => (
    <ClientCard key={index} data={service} />
  ))

  return (
    <Layout>
      <SEO data={seoMeta} />
      <Container>
        <div id='content' className="home page-padding">
          <section className="home-projects home-block">
            <SectionHeadline className="headblock" title="Projets" href="/projets" link="Voir tous les projets" />
            <div className="projects__home">{projectsList}</div>
          </section>

          <Spacer/>

          <section className="home-services home-block">
            <SectionHeadline className="headblock" title="Services" href="/services" link="Tous les services" />
            <div className="services__home">{servicesList}</div>
          </section>
        </div>
      </Container>

      <Spacer type="invisible"/>

      <section className="home-clients home-block">
        <Container>
          <SectionHeadline className="headblock" title="Clients" />
          <div className="clients__home">{clientsList}</div>
        </Container>
      </section>

    </Layout>
  )
}

export default IndexPage

export const query = graphql` query {
  projects : allProjectsJson {
    edges {
      node {
        path
        title
        categories
        description
        images {
          src
          alt
        }
      }
    }
  }
  projectsImages : allFile(filter: {relativePath: {regex: "/projet/"}}) {
    edges {
      node {
        relativePath
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  services : allServicesJson {
    edges {
      node {
        serviceId
        title
        image_path
        content
      }
    }
  }
  clients : allClientsJson {
    edges {
      node {
        name
        logo
        website
      }
    }
  }
}
`

// const onHeaderTyped = () => {
//   console.log('Ici il faudrait rajouter une classe sur Typist pour faire un style sélectionner')
//   console.log('J\'aimerais bien arrivé à un truc se rapporchant de https://gridsome.org/')
//   console.log('J\'aimerais bien arrivé à un truc se rapporchant de https://gridsome.org/')
//   console.log('On tape un mot, on passe une classe css "selected-text", on supprime tout d\'un coup, puis on tape un autre mot, etc... et on boucle')
//   console.log('Voir onCharacterTyped callback pour mettre une classe animation sur chaque lettre ? https://github.com/jstejada/react-typist#onCharacterTyped')
//   console.log('Plus facile avec https://cngu.github.io/vue-typer/')
// }
