import React from 'react'
import { Link } from 'gatsby'

const SectionHeadline = props =>
  <div className={props.className}>
    <h2 className="headblock__title">{props.title}</h2>
    <Link className="headblock__link" to={props.href}>
      <div className="horizontal-line"></div>
      { props.link && <span className="title" >{props.link}</span> }
    </Link>
  </div>

export default SectionHeadline
