const seoData = {
  "openGraph": {
    "id": "home",
    "title": "Création de site internet à Cahors",
    "description": "Agence Web à Cahors spécialisée en création et maintenance de site web, application mobile, référencement naturel SEO et mise en conformité RGPD.",
  },
  "microdata": {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Accueil",
    "mainEntity": {
      "@type": "WebSite",
      "url": "https://acolad.fr/",
      "name": "Acolad Développement",
      "citation": "Nous travaillons sur des projets agiles, robustes, inclusifs et conformes RGPD.",
      "image": "https://acolad.fr/static/images/acolad.png",
      "author": {
        "@type": "Organization",
        "name": "Acolad Développement",
        "sameAs": "https://acolad.fr/contact",
      },
      "publisher": {
        "@type": "Organization",
        "name": "Acolad Développement",
        "sameAs": "https://acolad.fr/contact",
      }
    }
  }
}

export default seoData
