import React from 'react'
import Spacer from '@shared/Spacer'

const Service = props => {
  const iconPath = require('@images/' + props.data.image_path) // import de l'icone svg
  return (
    <article className="card card--link card--service--home">
      <div className="link">
        <img className="image" src={iconPath} alt={props.data.title} />
        <div className="content">
          <h2 className="title">{props.data.title}</h2>
          <Spacer type="small"/>
          <p className="description">{props.data.content}</p>
        </div>
      </div>
    </article>
  )
}

export default Service
