import React from 'react'
import PropTypes from 'prop-types'

const Spacer = ({ type }) => {
  return (
    <hr className={'spacer spacer--' + type} />
  )
}

Spacer.propTypes = {
  size: PropTypes.string,
}

Spacer.defaultProps = {
  type: 'normal',
}

export default Spacer
